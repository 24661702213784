$(function () {
    $(document).on('change', '#around-input', function () {
        if (this.checked) {
            DisableSubmit();
            storeLocation($(this), EnableSubmit);
        }
        else {
            removeLocation();
            EnableSubmit();
        }
    });

    // Set a location from the browser while the page loads
    if ($('#around-input').is(':checked')) {
        DisableSubmit();
        storeLocation($('#around-input'), EnableSubmit);
    }
});

function DisableSubmit() {
    $("#search-button").prop("disabled", true);
}

function EnableSubmit() {
    $("#search-button").removeAttr('disabled');
}
